'use client';

import { Flex, Grid, GridItem, Skeleton, SkeletonText } from '@chakra-ui/react';

import styles from './styles.module.css';

export default function Loading() {
    return (
        <Grid className={styles['container-skeleton']}>
            {[1, 2, 3].map((item) => (
                <GridItem key={item}>
                    <Flex direction={'column'}>
                        <Skeleton height='100px' w='200px' borderRadius={'12px'} />
                        <SkeletonText mt='4' w='150px' noOfLines={2} spacing='4' skeletonHeight='2' />
                        <Skeleton mt='4' height='40px' w='180px' borderRadius={'12px'} />
                    </Flex>
                </GridItem>
            ))}
        </Grid>
    );
}
